import React from "react";
import { Link } from "react-router-dom";
import {Helmet} from "react-helmet";
const About=() =>{
return (
<>
<Helmet>
            <title>About - yoursite.com</title>
            <meta name="description" content="Lorem ipsum dolor sit amet" />
        </Helmet>
<div class="container-fluid position-relative p-0">
         

         <div class="container-fluid bg-primary py-5 bg-header" style={{marginBottom: "90px"}}>
             <div class="row py-5">
                 <div class="col-12 pt-lg-5 mt-lg-5 text-center">
                     <h1 class="display-4 text-white animated zoomIn">About Us</h1>
                     <a href="" class="h5 text-white">Home</a>
                     <i class="fa fa-circle text-white px-2"></i>
                     <a href="" class="h5 text-white">About</a>
                 </div>
             </div>
         </div>
     </div>

     <div class="container-fluid py-5 wow fadeInUp" data-wow-delay="0.1s">
<div class="container py-5">
<div class="row g-5">
<div class="col-lg-7">
<div class="section-title position-relative pb-3 mb-5">
<h5 class="fw-bold text-primary text-uppercase">About Us</h5>
<h1 class="mb-0">Know More About Us!</h1>
</div>
<p class="mb-4">Ranking World Digital was founded in 2019 by two over-thinkers yet contemplative digital advertising enthusiasts. We sat, stood and walked around in a living room, looking through a generously tall window that pretty much symbolised our vision. As we mentally rummaged through a pile of names for our cloud agency, we finalized and shook hands on our then newly found title – Ranking World Digital (RWD).</p>

<p class="mb-4">We don’t want to conquer the world but we certainly don’t want our expertise to be limited within the country. We wish to help each business rank higher digitally through the platforms we cater to and services we provide. Our services include – Website design & development, Organic marketing, Paid marketing, Social media, Content writing and Digital marketing consulting. And that’s why we named our cloud-based agency as Ranking World Digital.</p>
 
 
<Link to="/contact-us" class="btn btn-primary py-3 px-5 mt-3 wow zoomIn" data-wow-delay="0.9s">Request A Quote</Link>
</div>
<div class="col-lg-5" >
<div class="position-relative h-100">
<img class="position-absolute w-100 h-100 rounded wow zoomIn" data-wow-delay="0.9s" src="assets/img/about.jpg" style={{objectFit: "cover"}} />
</div>
</div>
</div>
</div>
</div>
<div class="container-fluid py-5 wow fadeInUp" data-wow-delay="0.1s">
<div class="container py-5">
<div class="row g-5">
<div class="col-lg-5" >
<div class="position-relative h-100">
<img class="position-absolute w-100 h-100 rounded wow zoomIn" data-wow-delay="0.9s" src="assets/img/about.jpg" style={{objectFit: "cover"}} />
</div>
</div>
<div class="col-lg-7">
<div class="section-title position-relative pb-3 mb-5">
<h5 class="fw-bold text-primary text-uppercase">About Us</h5>
<h1 class="mb-0">Know More About Us!</h1>
</div>
<p class="mb-4">Ranking World Digital was founded in 2019 by two over-thinkers yet contemplative digital advertising enthusiasts. We sat, stood and walked around in a living room, looking through a generously tall window that pretty much symbolised our vision. As we mentally rummaged through a pile of names for our cloud agency, we finalized and shook hands on our then newly found title – Ranking World Digital (RWD).</p>

<p class="mb-4">We don’t want to conquer the world but we certainly don’t want our expertise to be limited within the country. We wish to help each business rank higher digitally through the platforms we cater to and services we provide. Our services include – Website design & development, Organic marketing, Paid marketing, Social media, Content writing and Digital marketing consulting. And that’s why we named our cloud-based agency as Ranking World Digital.</p>
 
 
<Link to="/contact-us" class="btn btn-primary py-3 px-5 mt-3 wow zoomIn" data-wow-delay="0.9s">Request A Quote</Link>
</div>

</div>
</div>
</div>

<div class="container-fluid py-5 wow fadeInUp" data-wow-delay="0.1s">
<div class="container py-5">
<div class="section-title text-center position-relative pb-3 mb-5 mx-auto"style={{maxWidthh: "600px"}}>
<h5 class="fw-bold text-primary text-uppercase">Why Choose Us</h5>
<h1 class="mb-0">We Are Here to Grow Your Business Exponentially</h1>
</div>
<div class="row g-5">
<div class="col-lg-4">
<div class="row g-5">
<div class="col-12 wow zoomIn" data-wow-delay="0.2s">
<div class="bg-primary rounded d-flex align-items-center justify-content-center mb-3"style={{width: "60px", height: "60px"}}>
<i class="fa fa-cubes text-white"></i>
</div>
<h4>Best In Industry</h4>
<p class="mb-0">Magna sea eos sit dolor, ipsum amet lorem diam dolor eos et diam dolor</p>
</div>
<div class="col-12 wow zoomIn" data-wow-delay="0.6s">
<div class="bg-primary rounded d-flex align-items-center justify-content-center mb-3" style={{width: "60px", height: "60px"}}>
<i class="fa fa-cubes text-white"></i>
</div>
<h4>Award Winning</h4>
<p class="mb-0">Magna sea eos sit dolor, ipsum amet lorem diam dolor eos et diam dolor</p>
</div>
</div>
</div>
<div class="col-lg-4  wow zoomIn" data-wow-delay="0.9s"style={{minheight: "350px"}}>
<div class="position-relative h-100">
<img class="position-absolute w-100 h-100 rounded wow zoomIn" data-wow-delay="0.1s" src="assets/img/feature.jpg" style={{objectfit: "cover"}} />
</div>
</div>
<div class="col-lg-4">
<div class="row g-5">
<div class="col-12 wow zoomIn" data-wow-delay="0.4s">
<div class="bg-primary rounded d-flex align-items-center justify-content-center mb-3"style={{height: "60px",width: "60px"}}>
<i class="fa fa-cubes text-white"></i>
</div>
<h4>Professional Staff</h4>
<p class="mb-0">Magna sea eos sit dolor, ipsum amet lorem diam dolor eos et diam dolor</p>
</div>
<div class="col-12 wow zoomIn" data-wow-delay="0.8s">
<div class="bg-primary rounded d-flex align-items-center justify-content-center mb-3"style={{height: "60px",width: "60px"}}>
<i class="fa fa-cubes text-white"></i>
</div>
<h4>24/7 Support</h4>
<p class="mb-0">Magna sea eos sit dolor, ipsum amet lorem diam dolor eos et diam dolor</p>
</div>
</div>
</div>
</div>
</div>
</div>

</>
)
}

export  default About;