import React from "react";
import { Link, useParams } from "react-router-dom";
import { useState,useEffect } from "react";
import {Helmet} from "react-helmet";
const Postpage=() =>{
    
      const[items,setItems]=useState([]);
useEffect(() =>{

fetch("https://rankingworlddigital.in/admin/api/bloglist.php")
.then(res=>res.json())
.then(
(result)=>{

     
    setItems(result);

}

)

},[]

)
 
const {postId}=useParams() 
 
    const[item,setItem]=useState([]);
     
    useEffect(() =>{

        fetch(`https://rankingworlddigital.in/admin/api/getblog.php?id=${postId}`)
  .then(response => {
    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }
    return response.json();
  })
  .then(data => {
    // Handle the retrieved data here
    console.log(data);
    setItem(data);
    
    
     
  })
  
  .catch(error => {
    // Handle any errors that occur during the fetch request
    console.error(error);

  });
        
      
        
     
        
        },[]
        
        )
        
 
return (
<>
<Helmet>
{item.map(item =>(
            <title>{item.meta_title}</title>
            
            ))}
            {item.map(item =>(
                <meta name="description" content={item.meta_description}></meta>
            ))}
              {item.map(item =>(
                <meta name="keywords" content={item.meta_keyword}></meta>
            ))}
        </Helmet>


<div class="container-fluid position-relative p-0">
         

         <div class="container-fluid bg-primary py-5 bg-header" style={{marginBottom:"20px"}}>
             <div class="row py-5">
                 <div class="col-12 pt-lg-5 mt-lg-5 text-center">
                     
                     <a href="" class="h5 text-white">Home</a>
                     <i class="fa fa-circle text-white px-2"></i>
                     <a href="" class="h5 text-white">Blog</a>
                 </div>
             </div>
         </div>
     </div>

      <div class="container-fluid py-5 wow fadeInUp" data-wow-delay="0.1s" style={{visibility:"visible",animationdelay:"0.1s",animationname:"fadeInUp"}}>
        <div class="container py-5">
            <div class="row g-5">

            {item.map(item =>(
                <div class="col-lg-8">
                  
                    <div class="mb-5">
                        <img class="img-fluid w-100 rounded mb-5" src={item.photo} alt=""/>
                        <h1 class="mb-4">{item.news_title}</h1>
                        <p dangerouslySetInnerHTML={{ __html: item.news_content }} />
                       
                    </div>
                 
                    
                   
                  
                </div>
 
 ))}
                 
        <div class="col-lg-4">
                 
                    
                    
                     
                    
                    <div class="mb-5 wow slideInUp" data-wow-delay="0.1s">
                        <div class="section-title section-title-sm position-relative pb-3 mb-4">
                            <h3 class="mb-0">Recent Post</h3>
                        </div>
                        {items.map(items =>(
                        <div class="d-flex rounded overflow-hidden mb-3">
                            <img class="img-fluid" src={items.photo} style={{width:"100px",height:"100px",objectfit:"cover"}} alt=""/>
                            <Link className="h5 fw-semi-bold d-flex align-items-center bg-light px-3 mb-0"  to={'/post/'+items.news_slug}  target="_blank" onClick={() => {
    window.scroll(0, 0);
  }}>{items.news_title} </Link>
                             
                            
                        </div>
       ))}
                    </div>
                    
                     
                    
                     
                   
    
                   
                    
                    
                </div>   
            </div>
        </div>
    </div>
 
 


</>
)
}

export  default Postpage;