import React from "react";
 
import {Helmet} from "react-helmet";
import { Link } from "react-router-dom";
import { useState,useEffect } from "react";
const Contact=() =>{
    const[item,setItem]=useState([]);
    useEffect(() =>{
    
    fetch("https://rankingworlddigital.in/admin/api/setting.php")
    .then(res=>res.json())
    .then(
    (result)=>{
    
         
        setItem(result);
    
    }
    
    )
    
    },[]
    
    )
return (
<>
<Helmet>
            <title>Contact Us</title>
            <meta name="description" content="Lorem ipsum dolor sit amet" />
        </Helmet>

<div class="container-fluid position-relative p-0">
         

         <div class="container-fluid bg-primary py-5 bg-header" style={{marginBottom: "90px"}}>
             <div class="row py-5">
                 <div class="col-12 pt-lg-5 mt-lg-5 text-center">
                     <h1 class="display-4 text-white animated zoomIn">Contact Us</h1>
                     <a href="#" class="h5 text-white">Home</a>
                     <i class="fa fa-circle text-white px-2"></i>
                     <a href="#" class="h5 text-white">Contact Us</a>
                 </div>
             </div>
         </div>
     </div>

      
 
<div class="container-fluid py-5 wow fadeInUp" data-wow-delay="0.1s">
        <div class="container py-5">
            <div class="section-title text-center position-relative pb-3 mb-5 mx-auto" style={{maxWidth: "600px"}}>
                <h5 class="fw-bold text-primary text-uppercase">Contact Us</h5>
                <h1 class="mb-0">If You Have Any Query, Feel Free To Contact Us</h1>
            </div>
            <div class="row g-5 mb-5">
                <div class="col-lg-4">
                    <div class="d-flex align-items-center wow fadeIn" data-wow-delay="0.1s">
                        <div class="bg-primary d-flex align-items-center justify-content-center rounded" style={{width: "60px",height: "60px"}}>
                            <i class="fa fa-phone text-white"></i>
                        </div>
                        <div class="ps-4">
                            <h5 class="mb-2">Call to ask any question</h5>
                            {item.map(item =>(
                            <h4 class="text-primary mb-0">{item.contact_phone}</h4> ))}
                        </div>
                    </div>
                </div>
                <div class="col-lg-4">
                    <div class="d-flex align-items-center wow fadeIn" data-wow-delay="0.1s">
                        <div class="bg-primary d-flex align-items-center justify-content-center rounded" style={{width: "60px",height: "60px"}}>
                            <i class="fa fa-phone text-white"></i>
                        </div>
                        <div class="ps-4">
                            <h5 class="mb-2">Email to get free quote</h5>
                            {item.map(item =>(
                            <h4 class="text-primary mb-0">{item.contact_email}</h4>))}
                        </div>
                    </div>
                </div>
                
                <div class="col-lg-4">
                    <div class="d-flex align-items-center wow fadeIn" data-wow-delay="0.8s">
                        <div class="bg-primary d-flex align-items-center justify-content-center rounded" style={{width: "60px",height: "60px"}}>
                            <i class="fa fa-map text-white"></i>
                        </div>
                        <div class="ps-4">
                            <h5 class="mb-2">Visit our office</h5>
                            {item.map(item =>(
                            <h4 class="text-primary mb-0">{item.contact_address}</h4>))}
                        </div>
                    </div>
                </div>
            </div>
            <div class="row g-5">
                <div class="col-lg-6 wow slideInUp" data-wow-delay="0.3s">
                    <form>
                        <div class="row g-3">
                            <div class="col-md-6">
                                <input type="text" class="form-control border-0 bg-light px-4" placeholder="Your Name" style={{height: "55px"}}/>
                            </div>
                            <div class="col-md-6">
                                <input type="email" class="form-control border-0 bg-light px-4" placeholder="Your Email" style={{height: "55px"}}/>
                            </div>
                            <div class="col-12">
                                <input type="text" class="form-control border-0 bg-light px-4" placeholder="Subject" style={{height: "55px"}}/>
                            </div>
                            <div class="col-12">
                                <textarea class="form-control border-0 bg-light px-4 py-3" rows="4" placeholder="Message"></textarea>
                            </div>
                            <div class="col-12">
                                <button class="btn btn-primary w-100 py-3" type="submit">Send Message</button>
                            </div>
                        </div>
                    </form>
                </div>
                <div class="col-lg-6 wow slideInUp" data-wow-delay="0.6s">
                    <iframe class="position-relative rounded w-100 h-100"
                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3001156.4288297426!2d-78.01371936852176!3d42.72876761954724!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4ccc4bf0f123a5a9%3A0xddcfc6c1de189567!2sNew%20York%2C%20USA!5e0!3m2!1sen!2sbd!4v1603794290143!5m2!1sen!2sbd"
                        frameborder="0" style={{minheight: "350px", border:"0" 
                        }}></iframe>
                </div>
            </div>
        </div>
    </div>


</>
)
}

export  default Contact;