import React from "react";
import {Helmet} from "react-helmet";
import { useState,useEffect } from "react";
import { Link } from "react-router-dom";
 
 
const Home=() =>{
    const[item,setItem]=useState([]);
useEffect(() =>{

fetch("https://rankingworlddigital.in/admin/api/activeslider.php")
.then(res=>res.json())
.then(
(result)=>{     
setItem(result);
}
)
},[]

)

const[testim,setItemtest]=useState([]);
useEffect(() =>{

fetch("https://rankingworlddigital.in/admin/api/testimonial.php")
.then(res=>res.json())
.then(
(result)=>{     
  setItemtest(result);
}
)
},[]

)
const[items,setItems]=useState([]);
useEffect(() =>{

fetch("https://rankingworlddigital.in/admin/api/slider.php")
.then(res=>res.json())
.then(
(result)=>{     
setItems(result);
}
)
},[]

)

const[blog,setItemblog]=useState([]);
useEffect(() =>{

fetch("https://rankingworlddigital.in/admin/api/homeblog.php")
.then(res=>res.json())
.then(
(blogdata)=>{     
setItemblog(blogdata);
}
)
},[]

)
const[htitle,setItemti]=useState([]);
useEffect(() =>{

fetch("https://rankingworlddigital.in/admin/api/setting.php")
.then(res=>res.json())
.then(
(result)=>{

     
  setItemti(result);

}

)

},[]

)
return (
<>
<Helmet>
{htitle.map(htitle =>(
            <title>{htitle.meta_title_home} </title>
))}
 {htitle.map(htitle =>(
                <meta name="description" content={htitle.meta_description_home}></meta>
            ))}
              {htitle.map(htitle =>(
                <meta name="keywords" content={htitle.meta_keyword_home}></meta>
            ))}
        </Helmet>


<div class="container-fluid position-relative p-0">
<div id="header-carousel" class="carousel slide carousel-fade" data-bs-ride="carousel">
<div class="carousel-inner">
{item.map(item =>(
<div class="carousel-item active">
<img class="w-100" src={item.photo} alt="rankingworlddigital"/>
<div class="carousel-caption d-flex flex-column align-items-center justify-content-center">
<div class="p-3">
<h2 class="text-white text-uppercase mb-3 animated slideInDown">{item.heading}</h2>
<p class="display-1 text-white mb-md-4 animated zoomIn"style={{fontSize:"18px"}}>{item.content}</p>
<Link to="tel:+919833637924" class="btn btn-primary py-md-3 px-md-5 me-3 animated slideInLeft">Call Now </Link>
 
<Link to="/contact-us" class="btn btn-outline-light py-md-3 px-md-5 animated slideInRight ">Enquiry Now</Link>
</div>
</div>
</div>
))}
{items.map(items =>(
<div class="carousel-item">
<img class="w-100" src={items.photo} alt="rankingworlddigital"/>
<div class="carousel-caption d-flex flex-column align-items-center justify-content-center">
<div class="p-3">
<h2 class="text-white text-uppercase mb-3 animated slideInDown">{items.heading}</h2>
<p class="display-1 text-white mb-md-4 animated zoomIn" style={{fontSize:"18px"}}>{items.content}</p>
 
<Link to="tel:+919833637924" class="btn btn-primary py-md-3 px-md-5 me-3 animated slideInLeft">Call Now </Link>
 
<Link to="/contact-us" class="btn btn-outline-light py-md-3 px-md-5 animated slideInRight ">Enquiry Now</Link>
</div>
</div>
</div>

))}
</div>
<button class="carousel-control-prev" type="button" data-bs-target="#header-carousel"
data-bs-slide="prev">
<span class="carousel-control-prev-icon" aria-hidden="true"></span>
<span class="visually-hidden">Previous</span>
</button>
<button class="carousel-control-next" type="button" data-bs-target="#header-carousel"
data-bs-slide="next">
<span class="carousel-control-next-icon" aria-hidden="true"></span>
<span class="visually-hidden">Next</span>
</button>
</div>
</div>

<div class="modal fade" id="searchModal" tabindex="-1">
<div class="modal-dialog modal-fullscreen">
<div class="modal-content">
<div class="modal-header border-0">
<button type="button" class="btn bg-white btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
</div>
<div class="modal-body d-flex align-items-center justify-content-center">
<div class="input-group">
<input type="text" class="form-control bg-transparent border-primary p-3" placeholder="Type search keyword"/>
<button class="btn btn-primary px-4"><i class="bi bi-search"></i></button>
</div>
</div>
</div>
</div>
</div>

<div class="container-fluid facts py-5 pt-lg-0">
<div class="container py-5 pt-lg-0">
<div class="row gx-0">
<div class="col-lg-4 wow zoomIn" data-wow-delay="0.1s">
<div class="bg-primary shadow d-flex align-items-center justify-content-center p-4" style={{height: "150px"}}>
<div class="bg-white d-flex align-items-center justify-content-center rounded mb-2" style={{height: "60px",width: "60px"}}>
<i class="fa fa-users text-primary"></i>
</div>
<div class="ps-4">
<h5 class="text-white mb-0">Happy Clients</h5>
<h1 class="text-white mb-0" data-toggle="counter-up">12345</h1>
</div>
</div>
</div>
<div class="col-lg-4 wow zoomIn" data-wow-delay="0.3s">
<div class="bg-light shadow d-flex align-items-center justify-content-center p-4"style={{height: "150px"}}>
<div class="bg-primary d-flex align-items-center justify-content-center rounded mb-2" style={{height: "60px",width: "60px"}}>
<i class="fa fa-check text-white"></i>
</div>
<div class="ps-4">
<h5 class="text-primary mb-0">Projects Done</h5>
<h1 class="mb-0" data-toggle="counter-up">12345</h1>
</div>
</div>
</div>
<div class="col-lg-4 wow zoomIn" data-wow-delay="0.6s">
<div class="bg-primary shadow d-flex align-items-center justify-content-center p-4"style={{height: "150px"}} >
<div class="bg-white d-flex align-items-center justify-content-center rounded mb-2"style={{height: "60px",width: "60px"}}>
<i class="fa fa-check text-red"></i>
</div>
<div class="ps-4">
<h5 class="text-white mb-0">Win Awards</h5>
<h1 class="text-white mb-0" data-toggle="counter-up">12345</h1>
</div>
</div>
</div>
</div>
</div>
</div>

<div class="container-fluid py-5 wow fadeInUp" data-wow-delay="0.1s">
<div class="container py-5">
<div class="row g-5">
<div class="col-lg-7">
<div class="section-title position-relative pb-3 mb-5">
<h5 class="fw-bold text-primary text-uppercase">About Us</h5>
<h1 class="mb-0">The Best IT Solution With 10 Years of Experience</h1>
</div>
<p class="mb-4">Tempor erat elitr rebum at clita. Diam dolor diam ipsum et tempor sit. Aliqu diam amet diam et eos labore. Clita erat ipsum et lorem et sit, sed stet no labore lorem sit. Sanctus clita duo justo et tempor eirmod magna dolore erat amet</p>
<div class="row g-0 mb-3">
<div class="col-sm-6 wow zoomIn" data-wow-delay="0.2s">
<h5 class="mb-3"><i class="fa fa-check text-primary me-3"></i>Award Winning</h5>
<h5 class="mb-3"><i class="fa fa-check text-primary me-3"></i>Professional Staff</h5>
</div>
<div class="col-sm-6 wow zoomIn" data-wow-delay="0.4s">
<h5 class="mb-3"><i class="fa fa-check text-primary me-3"></i>24/7 Support</h5>
<h5 class="mb-3"><i class="fa fa-check text-primary me-3"></i>Fair Prices</h5>
</div>
</div>
<div class="d-flex align-items-center mb-4 wow fadeIn" data-wow-delay="0.6s">
<div class="bg-primary d-flex align-items-center justify-content-center rounded"style={{height: "60px",width: "60px"}}>
<i class="fa fa-phone text-white"></i>


</div>
<div class="ps-4">
<h5 class="mb-2">Call to ask any question</h5>
<h4 class="text-primary mb-0">+012 345 6789</h4>
</div>
</div>
<a href="quote.html" class="btn btn-primary py-3 px-5 mt-3 wow zoomIn" data-wow-delay="0.9s">Request A Quote</a>
</div>
<div class="col-lg-5" >
<div class="position-relative h-100">
<img class="position-absolute w-100 h-100 rounded wow zoomIn" data-wow-delay="0.9s" src="assets/img/about.jpg" style={{objectFit: "cover"}} />
</div>
</div>
</div>
</div>
</div>

<div class="container-fluid py-5 wow fadeInUp" data-wow-delay="0.1s">
<div class="container py-5">
<div class="section-title text-center position-relative pb-3 mb-5 mx-auto"style={{maxWidthh: "600px"}}>
<h5 class="fw-bold text-primary text-uppercase">Why Choose Us</h5>
<h1 class="mb-0">We Are Here to Grow Your Business Exponentially</h1>
</div>
<div class="row g-5">
<div class="col-lg-4">
<div class="row g-5">
<div class="col-12 wow zoomIn" data-wow-delay="0.2s">
<div class="bg-primary rounded d-flex align-items-center justify-content-center mb-3"style={{width: "60px", height: "60px"}}>
<i class="fa fa-cubes text-white"></i>
</div>
<h4>Best In Industry</h4>
<p class="mb-0">Magna sea eos sit dolor, ipsum amet lorem diam dolor eos et diam dolor</p>
</div>
<div class="col-12 wow zoomIn" data-wow-delay="0.6s">
<div class="bg-primary rounded d-flex align-items-center justify-content-center mb-3" style={{width: "60px", height: "60px"}}>
<i class="fa fa-cubes text-white"></i>
</div>
<h4>Award Winning</h4>
<p class="mb-0">Magna sea eos sit dolor, ipsum amet lorem diam dolor eos et diam dolor</p>
</div>
</div>
</div>
<div class="col-lg-4  wow zoomIn" data-wow-delay="0.9s"style={{minheight: "350px"}}>
<div class="position-relative h-100">
<img class="position-absolute w-100 h-100 rounded wow zoomIn" data-wow-delay="0.1s" src="assets/img/feature.jpg" style={{objectfit: "cover"}} />
</div>
</div>
<div class="col-lg-4">
<div class="row g-5">
<div class="col-12 wow zoomIn" data-wow-delay="0.4s">
<div class="bg-primary rounded d-flex align-items-center justify-content-center mb-3"style={{height: "60px",width: "60px"}}>
<i class="fa fa-cubes text-white"></i>
</div>
<h4>Professional Staff</h4>
<p class="mb-0">Magna sea eos sit dolor, ipsum amet lorem diam dolor eos et diam dolor</p>
</div>
<div class="col-12 wow zoomIn" data-wow-delay="0.8s">
<div class="bg-primary rounded d-flex align-items-center justify-content-center mb-3"style={{height: "60px",width: "60px"}}>
<i class="fa fa-cubes text-white"></i>
</div>
<h4>24/7 Support</h4>
<p class="mb-0">Magna sea eos sit dolor, ipsum amet lorem diam dolor eos et diam dolor</p>
</div>
</div>
</div>
</div>
</div>
</div>

<div class="container-fluid py-5 wow fadeInUp" data-wow-delay="0.1s">
<div class="container py-5">
<div class="section-title text-center position-relative pb-3 mb-5 mx-auto"style={{maxWidth: "600px"}}>
<h5 class="fw-bold text-primary text-uppercase">Our Services</h5>
<h1 class="mb-0">Custom IT Solutions for Your Successful Business</h1>
</div>
<div class="row g-5">
<div class="col-lg-4 col-md-6 wow zoomIn" data-wow-delay="0.3s">
<div class="service-item bg-light rounded d-flex flex-column align-items-center justify-content-center text-center">
<div class="service-icon">
<i class="fa fa-code text-white"></i>
</div>
<h4 class="mb-3">Cyber Security</h4>
<p class="m-0">Amet justo dolor lorem kasd amet magna sea stet eos vero lorem ipsum dolore sed</p>
 
 
</div>
</div>
<div class="col-lg-4 col-md-6 wow zoomIn" data-wow-delay="0.6s">
<div class="service-item bg-light rounded d-flex flex-column align-items-center justify-content-center text-center">
<div class="service-icon">
<i class="fa fa-code text-white"></i>
</div>
<h4 class="mb-3">Data Analytics</h4>
<p class="m-0">Amet justo dolor lorem kasd amet magna sea stet eos vero lorem ipsum dolore sed</p>
 
 
</div>
</div>
<div class="col-lg-4 col-md-6 wow zoomIn" data-wow-delay="0.9s">
<div class="service-item bg-light rounded d-flex flex-column align-items-center justify-content-center text-center">
<div class="service-icon">
<i class="fa fa-code text-white"></i>
</div>
<h4 class="mb-3">Web Development</h4>
<p class="m-0">Amet justo dolor lorem kasd amet magna sea stet eos vero lorem ipsum dolore sed</p>
 
</div>
</div>
<div class="col-lg-4 col-md-6 wow zoomIn" data-wow-delay="0.3s">
<div class="service-item bg-light rounded d-flex flex-column align-items-center justify-content-center text-center">
<div class="service-icon">
<i class="fa fa-android text-white"></i>
</div>
<h4 class="mb-3">Apps Development</h4>
<p class="m-0">Amet justo dolor lorem kasd amet magna sea stet eos vero lorem ipsum dolore sed</p>
 
</div>
</div>
<div class="col-lg-4 col-md-6 wow zoomIn" data-wow-delay="0.6s">
<div class="service-item bg-light rounded d-flex flex-column align-items-center justify-content-center text-center">
<div class="service-icon">
<i class="fa fa-search text-white"></i>
</div>
<h4 class="mb-3">SEO Optimization</h4>
<p class="m-0">Amet justo dolor lorem kasd amet magna sea stet eos vero lorem ipsum dolore sed</p>
 
</div>
</div>
<div class="col-lg-4 col-md-6 wow zoomIn" data-wow-delay="0.9s">
<div class="position-relative bg-primary rounded h-100 d-flex flex-column align-items-center justify-content-center text-center p-5">
<h3 class="text-white mb-3">Call Us For Quote</h3>
<p class="text-white mb-3">Clita ipsum magna kasd rebum at ipsum amet dolor justo dolor est magna stet eirmod</p>
<h2 class="text-white mb-0">+012 345 6789</h2>
</div>
</div>
</div>
</div>
</div>

 
<div class="container-fluid py-5 wow fadeInUp" data-wow-delay="0.1s">
<div class="container py-5">
<div class="row g-5">
<div class="col-lg-7">
<div class="section-title position-relative pb-3 mb-5">
<h5 class="fw-bold text-primary text-uppercase">Request A Quote</h5>
<h1 class="mb-0">Need A Free Quote? Please Feel Free to Contact Us</h1>
</div>
<div class="row gx-3">
<div class="col-sm-6 wow zoomIn" data-wow-delay="0.2s">
<h5 class="mb-4"><i class="fa fa-reply text-primary me-3"></i>Reply within 24 hours</h5>
</div>
<div class="col-sm-6 wow zoomIn" data-wow-delay="0.4s">
<h5 class="mb-4"><i class="fa fa-phone text-primary me-3"></i>24 hrs telephone support</h5>
</div>
</div>
<p class="mb-4">Eirmod sed tempor lorem ut dolores. Aliquyam sit sadipscing kasd ipsum. Dolor ea et dolore et at sea ea at dolor, justo ipsum duo rebum sea invidunt voluptua. Eos vero eos vero ea et dolore eirmod et. Dolores diam duo invidunt lorem. Elitr ut dolores magna sit. Sea dolore sanctus sed et. Takimata takimata sanctus sed.</p>
<div class="d-flex align-items-center mt-2 wow zoomIn" data-wow-delay="0.6s">
<div class="bg-primary d-flex align-items-center justify-content-center rounded"style={{height: "60px",width: "60px"}} >
<i class="fa fa-phone text-white"></i>
</div>
<div class="ps-4">
<h5 class="mb-2">Call to ask any question</h5>
<h4 class="text-primary mb-0">+012 345 6789</h4>
</div>
</div>
</div>
<div class="col-lg-5">
<div class="bg-primary rounded h-100 d-flex align-items-center p-5 wow zoomIn" data-wow-delay="0.9s">
<form>
<div class="row g-3">
<div class="col-xl-12">
<input type="text" class="form-control bg-light border-0" placeholder="Your Name" />
</div>
<div class="col-12">
<input type="email" class="form-control bg-light border-0" placeholder="Your Email" />
</div>
<div class="col-12">
<select class="form-select bg-light border-0" >
<option selected>Select A Service</option>
<option value="1">Service 1</option>
<option value="2">Service 2</option>
<option value="3">Service 3</option>
</select>
</div>
<div class="col-12">
<textarea class="form-control bg-light border-0" rows="3" placeholder="Message"></textarea>
</div>
<div class="col-12">
<button class="btn btn-dark w-100 py-3" type="submit">Request A Quote</button>
</div>
</div>
</form>
</div>
</div>
</div>
</div>
</div>

<div class="container-fluid py-5 wow fadeInUp" data-wow-delay="0.1s">
<div class="container py-5">
<div class="section-title text-center position-relative pb-3 mb-4 mx-auto" style={{maxwidth: "600px"}}>
<h5 class="fw-bold text-primary text-uppercase">Testimonial</h5>
<h1 class="mb-0">What Our Clients Say About Our Digital Services</h1>
</div>
 <div class="row text-center">
 {testim.map(testim =>(
    <div class="col-md-4 mb-5 mb-md-0">
      <div class="d-flex justify-content-center mb-4">
        <img src="https://mdbcdn.b-cdn.net/img/Photos/Avatars/img%20(1).webp"
          class="rounded-circle shadow-1-strong" width="150" height="150" />
      </div>
      <h5 class="mb-3">Maria Smantha</h5>
      <h6 class="text-primary mb-3">Web Developer</h6>
      <p class="px-xl-3">
        <i class="fa fa-quote-left pe-2"></i>Lorem ipsum dolor sit amet, consectetur
        adipisicing elit. Quod eos id officiis hic tenetur quae quaerat ad velit ab hic
        tenetur.
      </p>
      <ul class="list-unstyled d-flex justify-content-center mb-0">
        <li>
          <i class="fa fa-star fa-sm text-warning"></i>
        </li>
        <li>
          <i class="fa fa-star fa-sm text-warning"></i>
        </li>
        <li>
          <i class="fa fa-star fa-sm text-warning"></i>
        </li>
        <li>
          <i class="fa fa-star fa-sm text-warning"></i>
        </li>
        <li>
          <i class="fa fa-star-half-alt fa-sm text-warning"></i>
        </li>
      </ul>
    </div>
 ))}
     
  </div>
</div>
</div>

 

 <div class="container-fluid py-5 wow fadeInUp" data-wow-delay="0.1s">
<div class="container py-5">
<div class="section-title text-center position-relative pb-3 mb-5 mx-auto" style={{maxWidth: "600px"}}>
<h5 class="fw-bold text-primary text-uppercase">Latest Blog</h5>
<h1 class="mb-0">Read The Latest Articles from Our Blog Post</h1>
</div>
<div class="row g-5">
{blog.map(blog =>(
<div class="col-lg-4 wow slideInUp" data-wow-delay="0.3s">
<div class="blog-item bg-light rounded overflow-hidden">
<div class="blog-img position-relative overflow-hidden">
<img class="img-fluid" src={blog.photo} alt=""/>
 
</div>
<div class="p-4">
<div class="d-flex mb-3">
<small class="me-3"><i class="fa fa-user" aria-hidden="true"></i>
{blog.publisher}</small>
<small><i class="fa fa-calendar" aria-hidden="true"></i>
{blog.news_date}</small>
</div>
<Link  to={'/post/'+blog.news_slug}  onClick={() => {
    window.scroll(0, 0);
  }}><h4 class="mb-3">{blog.news_title}</h4></Link>
 
<Link  to={'/post/'+blog.news_slug}  onClick={() => {
    window.scroll(0, 0);
  }}>Read More <i className="bi bi-arrow-right"></i></Link>
</div>
</div>
</div>
))}

</div>
</div>
</div>



  
 
 
 





</>


)


}
export default Home;